import {
  setRemoteDefinitions,
  MfeConfigManager,
} from '@tailoor.mfe.monorepo/load-remote-module';

const hostName = window.location.hostname;

try {
  const urls = [
    '/assets/module-federation.manifest.json',
    `${process.env.NX_REACT_APP_API_URL}/api/v1/customers/url/${hostName}`,
  ];

  const res = await Promise.all(urls.map((e) => fetch(e)));

  // GET ALL MFES FROM JSON  CHOOSEN FROM CLI AMD FROM API
  const [definitionsDefault, customerData] = await Promise.all(
    res.map((e) => e.json())
  );

  const customerMfeConfig = new MfeConfigManager(customerData.mfeLinks);

  // SEARCH FOR THE ONES CHOOSEN FROM CLI HAVING LOCALHOST (THEY HAVE PRECEDENCE)
  const localhostDebugMfeArray = Object.keys(definitionsDefault)
    .map((key) => ({
      name: key,
      value: definitionsDefault[key],
    }))
    .filter((xx) => xx.value.includes('localhost'));

  // TRANSFORM BACK IN OBJECT (FROM ARRAY)
  const localhostDebugMfe = Object.assign(
    {},
    ...localhostDebugMfeArray.map((item) => ({ [item.name]: item.value }))
  );

  // MERGE LOCAL AND REMOTEFROM API
  const mergedLocals = {
    ...customerMfeConfig.getModuleUrls(),
    ...localhostDebugMfe,
  };

  // MERGE LOCAL  AND REMOTE + OTHER FROM JSONDEFAULT
  const resultsDefinition = { ...definitionsDefault, ...mergedLocals };

  console.log({ resultsDefinition });

  setRemoteDefinitions(resultsDefinition);
  import('./bootstrap').catch((err) => console.error(err));
} catch (err) {
  console.log(err);
}

// fetch('/assets/module-federation.manifest.json')
//   //  ADD CORRECT ENPOINT (LOCAL OR REMOTE TO RETRIVE module-federation.manifest.json)
//   // fetch(`https://localhost:3000/${domain}`)
//   .then((res) => res.json())
//   .then((definitions) => {
//     console.log({ definitions });

//     return setRemoteDefinitions(definitions);
//   })
//   .then(() => import('./bootstrap').catch((err) => console.error(err)));
